import React from 'react'

export const Redirect = () => {
    // Extract the query parameters from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    // Construct the deep link URL to your app
    let redirectUrl = `burrebudgeter://auth/callback?code=${code}`;
    if (state) {
        redirectUrl += `&state=${state}`;
    }

    // Redirect to your app
    window.location.href = redirectUrl;
    return (
        <body>
        <h1>Redirecting to Budgeter App...</h1>
        <p>If you are not automatically redirected, <a id="manual-redirect" href="#" onClick={() => window.location.href = redirectUrl}>click here</a>.</p>

        </body>
    )
}
