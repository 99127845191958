import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from "react-router-dom";
import StockholmClock from "./StockholmClock";

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%'
})

const MenuItem = styled(Link)`
  font-size: 14px;
  font-weight: 400;
  color: #F2F2F2;
  text-align: center;
  flex: 1;
  text-decoration: none;

  &:hover {
    opacity: 75%;
    cursor: pointer
  }
  &:active {
    opacity: 75%;
  }
`

interface Props {}

const prefixTitle = (pathname: string, path: string) => {
  return `${pathname === `/${path}` ? '>' : ''}${path}`
}

const MenuBar: React.FC<Props> = () => {
  const location = useLocation();


  return (
    <Container>
      <StockholmClock />
      <MenuItem to={'/'}>{prefixTitle(location.pathname, '')}home</MenuItem>
      <MenuItem to={'/projects'}>{prefixTitle(location.pathname, 'projects')}</MenuItem>
      <MenuItem to={'/thoughts'}>{prefixTitle(location.pathname, 'thoughts')}</MenuItem>
    </Container>
  );
}


export default MenuBar;
