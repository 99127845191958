import React from 'react';
import styled from "styled-components";
import { ShadedImage } from "../../../components";

interface Props {}

const Container = styled.div`
  display: flex;
  margin-top: 24px;
`;

const Title = styled.div`
  color: white;
`;

const Home: React.FC<Props> = () => {
  return (
    <Container>
      {/*<ShadedImage src={require('../../../../assets/images/me.png')} style={{height: 200, width: 200}}/>*/}
      <Title></Title>
    </Container>
  );
}

export default Home;
