import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Thoughts } from "./lib/Menu/components/Thoughts";
import { Projects } from "./lib/Menu/components/Projects";
import {Redirect} from "./lib/Budget/Redirect";

require("typeface-roboto-mono");

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="thoughts" element={<Thoughts />} />
          <Route path="projects" element={<Projects />} />
        </Route>
          <Route path="/budget" element={<Redirect />}>
              <Route path="redirect" element={<Redirect />} />
          </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
