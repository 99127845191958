import React from 'react';
import styled from 'styled-components';
import {Thought} from "./components";

interface Props {}

interface ThoughtsInterface {
  ts: number;
  title: string;
  txt: string;
  link?: string;
}

const thoughts: ThoughtsInterface[] = require('../../../../data/thoughts/thoughts.json');
thoughts.sort((t1, t2) => t1.ts < t2.ts ? 1 : -1);

const Container = styled.div({})

const Divider = styled.div({
  height: 1,
  marginLeft: 16,
  marginRight: 16,
  marginTop: 24,
  backgroundColor: 'rgba(242, 242, 242, 0.2)',
})

const Thoughts: React.FC<Props> = () => {
  return (
    <Container>
      {thoughts.map(({ts, title, txt}, index) => (
        <div key={ts}>
          <Thought ts={ts} title={title} txt={txt} />
          {index !== thoughts.length - 1 ? <Divider /> : null}
        </div>
      ))}
    </Container>
  );
}

export default Thoughts;