import React from 'react';
import { MenuBar } from "./components";
import {Outlet, useLocation} from "react-router-dom";
import {Home} from "./components/Home";

const Menu: React.FC = () => {
  const location = useLocation();

  console.log(location.pathname);
  return (
      <>
        <MenuBar/>
        {
          location.pathname === '/' ? (
              <Home/>
          ) : (
              <Outlet/>
          )
        }
      </>
  )
}

export default Menu;
